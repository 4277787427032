import React, {useState} from "react";
import { Link, useStaticQuery, navigate, graphql } from "gatsby";
import Img from "gatsby-image";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';
import "./header.scss";

//ICONS
import { RiMailLine } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";

const Header = ({checked}) => {

  const changeLanguage = (code) => {
    switch(code){
      case "MX":
        navigate("/")
        break;
      case "US":
        navigate("/en")
        break;
    }
  }

  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(relativePath: { eq: "cie-wn.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const sitePages = [
    {
      name: 'Home',
      slug: '/en/'
    },
    {
      name: 'Who are we',
      slug: '/en/#nosotros'
    },
    {
      name: 'Mision, Vision and Values',
      slug: '/en/mision'
    },
    {
      name : 'Contact',
      slug: '/en/#contact'
    }
  ]

  const [navActive, setNavActive] = useState(false)


  return(
    <header>
      <div className="tile">
          <ul className={"tile-links"}>
            <li><a href="tel:+52 81 17 43 42 07"> <FaPhone style={{ marginRight: "8px" }} /> +52 81 17 43 42 07</a></li>
          </ul>
          <ul className={"tile-links " + [navActive ? "tile-links-over" : ""]}>
          <li> <a href="mailto:contact@cienegocios.com"> <RiMailLine /> </a> </li>
          <li> <a href="https://www.facebook.com/cienegociosmx/"> <FaFacebookF /> </a> </li>
            <li> <a href="https://twitter.com/CIE38892629"> <GrTwitter /> </a> </li>
            <li> <a href="https://www.linkedin.com/company/cienegociosmx"> <FaLinkedinIn /> </a> </li>
          </ul>
      </div>
      
      <nav>
          <div className="logo">
            <Link to="/en" className="logo-cont">
              <Img fluid={data.headerLogo.childImageSharp.fluid} className="logo-img" alt="CIE Negocios" />  
              <div className="name">
                <span>Integral</span>
                <span><i>Structured</i></span>
                <span>Consulting</span>
              </div>
            </Link>
          </div>
          <ul className={"nav-links " + [navActive ? "nav-active" : ""]}>
            {
              sitePages.map(p => 
                <li><Link to={p.slug} className={["nav-link " + [checked === p.name ? "nav-link-checked" : ""]]}> {checked === p.name ? "O" : "" } {p.name}</Link></li>
              )
            }

          </ul>
          <div className={"burger " + [navActive ? "burger-active" : ""]} onClick={() => setNavActive(!navActive) }>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
      </nav>

    </header>
  )
}

export default Header;
