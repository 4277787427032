import React from "react"
import { graphql, useStaticQuery ,Link } from "gatsby"
import SEO from "../../components/seo";
import Img from "gatsby-image";

import Header from "../../components/en/header";
import Footer from "../../components/footer";

import "./../../styles/mision.scss";

//Icon
import { BiArrowBack } from "react-icons/bi";


const Mision = () => {

  //Images
  const images = useStaticQuery(graphql`
        query {
            mision: file(relativePath: { eq: "mision.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            vision: file(relativePath: { eq: "consultoria_para_empresas_CIE_vision.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            valores: file(relativePath: { eq: "consultoria_para_empresas_CIE_valores.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            objetivo: file(relativePath: { eq: "objetivo.jpg" }) {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
  `);

  let data = {
    "Mision": "Support owners and chief managers to resolve their needs and generate them instant solutions that allows them to efficientize their administrative processes and optimize their operating results.",
    "Vision": "Be the solution for a service integration requirement, so our customers don't need to develop especialized departments because they trust us to get agile solutions. Without the necesity of having especialized employees because we can service them by event, project or specific solution, and always being available for future services and needs.",
    "Valores": <ul>
                <li>- Honesty.</li>
                <li>- Profesionalism.</li>
                <li>- Complete compromise with customers.</li>
                <li>- Total integrity in supporting and offering solutions.</li>
              </ul>,
    "Objetivo": "Be an integrative solucion for specific services our customers need, then they can rely in our professional support team, willing to give them inmediate solucions to their needs at anytime",
  }

  return (
    <section>
      <SEO
        title="Mision"
        description="At CIE, our mision is.."
      />
      <Header checked={"Mision, Vision and Values"} />

      <article className="mision">

        <div className="title">
          <Link to="/en/" className="link" > <BiArrowBack size="18" className="icon" /> Home </Link>
          <h1>Mision, Vision, Values and Objective</h1>
        </div>

        <main>


          <div className="el el-l">
            <div className="text">
              <h2>Mision</h2>
              <p>{data.Mision}</p>
            </div>
            <Img fluid={images.mision.childImageSharp.fluid} className="img" alt="Negociation between two people"/>
          </div>

          <div className="el el-r">
            <div className="text">
              <h2>Vision</h2>
              <p>{data.Vision}</p>
            </div>
            <Img fluid={images.vision.childImageSharp.fluid} className="img" alt="Consulting office" />
          </div>

          <div className="el el-l">
            <div className="text">
              <h2>Values</h2>
              <p>{data.Valores}</p>
            </div>
            <Img fluid={images.valores.childImageSharp.fluid} className="img" alt="Consulting office"/>
          </div>

          <div className="el el-r">
            <div className="text">
              <h2>Objective</h2>
              <p>{data.Objetivo}</p>
            </div>
            <Img fluid={images.objetivo.childImageSharp.fluid} className="img" alt="Business office"/>
          </div>



        </main>

      </article>

      <Footer lang="en" />

    </section>
  );
}

export default Mision;
